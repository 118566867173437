var $class="se2--footer-global-se--subscribe-form",$name="FooterGlobalSE/subscribeForm",$path="app/components/FooterGlobalSE/partials/subscribeForm/index.js",$this={$class,$name,$path,};import onFormSubmitMessage from 'app/components/utilities/onFormSubmitMessage';
import validateFormElements from 'app/components/utilities/validateFormElements';
import sha256 from 'app/utilities/sha256';

import analytics from 'app/modules/analytics';
import shell from 'app/modules/shell';

import { css, messages } from 'configs';
import {
  footerCheckboxClassName,
  footerCheckboxContainerSelector,
  footerChinaCheckboxClassName,
  inputCheckboxSelector,
  inputPrivacyCheckbox,
} from 'app/components/FooterGlobalSE/config';

export default shell.registerPartial($this, ({ addEventListener, mount, subscribeToMessage }) => {
  let form = null;

  const trackAnalytics = async () => {
    const formId = form.id;
    const formObj = Object.fromEntries(new FormData(form));
    const formEmail = formObj.Email || formObj.email || formObj.EmailOrPhone || formObj.emailOrPhone;

    const lowercaseEmail = formEmail ? formEmail.toLowerCase() : ' ';
    let userHashedEmail;
    await sha256(lowercaseEmail).then((hex) => { userHashedEmail = hex; });

    analytics.trackNewsletterSubscription({ siteLocationKey: 'Footer' });
    analytics.trackContactSubmit({
      formId,
      userHashedEmail,
      formObj,
    });
  };

  mount((element) => {
    form = element.querySelector('form');
    const footerCheckboxContainerElement = form
      .querySelector(footerCheckboxContainerSelector);
    const footerInputCheckboxElement = form
      .querySelector(`${footerCheckboxContainerSelector} ${inputCheckboxSelector}`);
    const submitButtonElement = form
      .querySelector(`.${css.classNames.submitButton}`);
    const privacyCheckBoxClass = form.querySelector(`${inputPrivacyCheckbox}`);
    const formEmail = form.querySelector('.input-email');
    const ima = form.querySelector('.input-select');
    const formAction = form.getAttribute('action');

    const { country } = form.dataset;

    if (country === 'China') {
      submitButtonElement.disabled = true;
    }

    const disableForm = (formElement) => {
      if (!formElement?.elements) return;

      Array.from(formElement.elements).forEach((subformElement) => {
        subformElement.disabled = true;
      });
    };

    // Disable the form when form action (postDataUrl) is empty.
    if (!formAction) {
      disableForm(form);
    }

    // eslint-disable-next-line no-return-assign
    const enableSubmitIfCheckboxPresents = () => submitButtonElement
      .disabled = !(submitButtonElement && footerInputCheckboxElement?.checked);

    const showCheckboxIfCheckboxPresents = ({ resolved, recaptchaId }) => {
      if (!form.querySelector(`#${recaptchaId}`) || !footerCheckboxContainerElement) return;
      if (resolved === true && footerCheckboxContainerElement) {
        footerCheckboxContainerElement.classList.remove(css.classNames.hide);
      }
    };

    if (footerCheckboxContainerElement?.classList.contains(footerCheckboxClassName)) {
      subscribeToMessage(messages.recaptcha, showCheckboxIfCheckboxPresents);
    } else if (footerCheckboxContainerElement?.classList.contains(footerChinaCheckboxClassName)) {
      footerCheckboxContainerElement.classList.remove(css.classNames.hide);
      submitButtonElement.disabled = true;
    }

    subscribeToMessage(
      messages.formSubmit,
      ({ formId, status }) => onFormSubmitMessage(form, formId, status, trackAnalytics),
    );

    const privacyCheckboxValidation = () => (privacyCheckBoxClass?.checked
      ? privacyCheckBoxClass?.classList.remove('error')
      : privacyCheckBoxClass?.classList.add('error'));

    const enableSubmitForChina = () => {
      if (country === 'China') {
        if (privacyCheckBoxClass?.checked && formEmail?.value && ima?.value) {
          submitButtonElement.disabled = false;
        } else {
          submitButtonElement.disabled = true;
        }
        privacyCheckboxValidation();
      }
    };

    addEventListener(footerInputCheckboxElement, 'click', enableSubmitIfCheckboxPresents);
    addEventListener(form, 'submit', validateFormElements);
    addEventListener(submitButtonElement, 'click', () => validateFormElements(form));
    addEventListener(privacyCheckBoxClass, 'click', enableSubmitForChina);
    addEventListener(element, 'keyup', enableSubmitForChina);
    addEventListener(ima, 'change', enableSubmitForChina);
  });
});
